import React from "react";
import Box from "@mui/material/Box";
// import AppBar from "@mui/material/AppBar";
import SideDrawer from "./SideDrawer";
import { useEffect, useState, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "./auth";
import Button from "@mui/material/Button";

const columns = [
  {
    field: "SKU",
    headerName: "Product SKU",
    width: 220,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "OrderId",
    headerName: "Order Id",
    width: 140,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Quantity",
    headerName: "Quantity",
    width: 120,
    editable: true,
    headerAlign: "center",
    align: "center",
  },

  {
    field: "CreatedAt",
    headerName: "CreatedAt",
    width: 160,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "ShopifyOrderId",
    headerName: "",
    width: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Button
        variant="outlined"
        onClick={() =>
          window.open(
            `https://admin.shopify.com/store/fairliarusa/orders/${params.row.ShopifyOrderId}`,
            "_blank"
          )
        }
      >
        View
      </Button>
    ),
  },
];

const Inventory = () => {
  const [rowData, setRowData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  });

  useEffect(() => {
    fetch(
      "https://2qsdq8hwy5.execute-api.us-east-1.amazonaws.com/prod/inflatyWarehouse"
    )
      .then((res) => res.json())
      .then((result) => setRowData(result));
  }, []);

  return (
    <Box sx={{ bgcolor: "#F5F6F8", display: "flex", height: "100vh" }}>
      <SideDrawer />
      <Box
        component="main"
        sx={{
          width: "100%",
          padding: 5,
        }}
      >
        <DataGrid
          rows={rowData}
          getRowHeight={() => "auto"}
          columns={columns}
          pageSize={100}
          align="center"
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          getRowId={(row) => row.ID}
          sortModel={[
            {
              field: "CreatedAt",
              sort: "desc", // "asc" for ascending order, "desc" for descending order
            },
          ]}
          sx={{
            border: "none",
            boxShadow: 2,
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "black",
              color: "white",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-sortIcon": {
              color: "white",
            },
            "& .MuiDataGrid-menuIconButton": {
              color: "white",
            },
            "& .MuiDataGrid-cell": {
              padding: "20px",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Inventory;
