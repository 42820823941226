import React from "react";
import Box from "@mui/material/Box";
// import AppBar from "@mui/material/AppBar";
import SideDrawer from "./SideDrawer";
import { useEffect, useState, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "./auth";
import SyncIcon from "@mui/icons-material/Sync";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Sync = () => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  });

  const syncProducts = () => {
    fetch(
      "https://2qsdq8hwy5.execute-api.us-east-1.amazonaws.com/prod/productSync"
    ).then(setOpen(true));
  };

  return (
    <Box sx={{ bgcolor: "#F5F6F8", display: "flex", height: "100vh" }}>
      <SideDrawer />
      <Box
        component="main"
        sx={{
          width: "100%",
          padding: "40px",
        }}
      >
        <Box
          component="main"
          sx={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
            width: "400px",
          }}
        >
          <Typography
            sx={{ fontWeight: "300", marginBottom: "20px" }}
            variant="h5"
            gutterBottom
          >
            Shopify Sync
          </Typography>
          <Button
            onClick={syncProducts}
            sx={{
              backgroundColor: "#ff385c",
              ":hover": {
                bgcolor: "#ff385cd1",
              },
            }}
            variant="contained"
            endIcon={<SyncIcon />}
          >
            Sync
          </Button>
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Sync has started!
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};

export default Sync;
