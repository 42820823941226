import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import Sync from "./components/Sync";
import Inventory from "./components/Inventory";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sync" element={<Sync />} />
        <Route path="/inflatyWarehouse" element={<Inventory />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
