import React from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import StoreIcon from "@mui/icons-material/Store";
import InventoryIcon from "@mui/icons-material/Inventory";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import SyncIcon from "@mui/icons-material/Sync";

import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "./auth";

const drawerWidth = 240;

const SideDrawer = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  const goToInflaty = () => {
    navigate("/inflatyWarehouse");
  };

  const goToSync = () => {
    navigate("/sync");
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          borderTopRightRadius: 50,
          borderBottomRightRadius: 50,
          boxShadow: "0px 0px 8px 0px rgb(0 0 0 / 25%)",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar sx={{ justifyContent: "center", minHeight: "90px !important" }}>
        <img src="/images/fl_logo.png" alt="fl logo" width="60px" />
      </Toolbar>
      <Divider />
      <List>
        <ListItem key="Home" disablePadding>
          <ListItemButton onClick={goToHome}>
            <ListItemIcon>
              <HomeIcon sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem key="Inflaty" disablePadding>
          <ListItemButton onClick={goToInflaty}>
            <ListItemIcon>
              <InventoryIcon sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary="Inflaty" />
          </ListItemButton>
        </ListItem>
        <ListItem key="Settings" disablePadding>
          <ListItemButton onClick={goToSync}>
            <ListItemIcon>
              <SyncIcon sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary="Sync" />
          </ListItemButton>
        </ListItem>
        {/* <ListItem key="Store" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <StoreIcon sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary="Store" />
          </ListItemButton>
        </ListItem> */}
      </List>
      <Divider />
      <List>
        <ListItem key="Settings" disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideDrawer;
