import React from "react";
import Box from "@mui/material/Box";
// import AppBar from "@mui/material/AppBar";
import SideDrawer from "./SideDrawer";
import Skeleton from "@mui/material/Skeleton";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState, useCallback } from "react";
import ImageIcon from "@mui/icons-material/Image";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColumnMenu,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "./auth";

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ padding: "10px" }}>
      <GridToolbarColumnsButton sx={{ color: "#000000" }} />
      <GridToolbarFilterButton sx={{ color: "#000000" }} />
      <GridToolbarExport sx={{ color: "#000000" }} />
    </GridToolbarContainer>
  );
}

const columns = [
  {
    field: "Image",
    headerName: "Image",
    width: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      if (params.value) {
        return (
          <img
            src={params.value}
            alt="Product image"
            style={{ width: "90px", padding: "10px" }}
          />
        );
      }
    },
  },
  {
    field: "SKU",
    headerName: "Product SKU",
    width: 220,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "InventoryUS",
    headerName: "US",
    width: 120,
    editable: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "InventoryKorea",
    headerName: "Korea",
    width: 140,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "WarehouseQty",
    headerName: "Warehouse Qty",
    width: 140,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Price",
    headerName: "Price",
    width: 160,
    headerAlign: "center",
    align: "center",
  },
];

const Home = () => {
  const [rowData, setRowData] = useState([]);

  const [snackbar, setSnackbar] = useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  });

  const processRowUpdate = (newRow) => {
    fetch(
      "https://2qsdq8hwy5.execute-api.us-east-1.amazonaws.com/prod/products",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sku: newRow.SKU, quantity: newRow.InventoryUS }),
      }
    ).then(
      setSnackbar({
        children: "User successfully saved",
        severity: "success",
      })
    );

    return newRow;
  };

  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  useEffect(() => {
    fetch(
      "https://2qsdq8hwy5.execute-api.us-east-1.amazonaws.com/prod/products"
    )
      .then((res) => res.json())
      .then((result) => setRowData(result));
  }, []);

  return (
    <Box sx={{ bgcolor: "#F5F6F8", display: "flex", height: "100vh" }}>
      <SideDrawer />
      <Box
        component="main"
        sx={{
          width: "100%",
          padding: 5,
        }}
      >
        <DataGrid
          rows={rowData}
          getRowHeight={() => "auto"}
          columns={columns}
          pageSize={100}
          loading={rowData.length === 0}
          slots={{
            toolbar: CustomToolbar,
            columnMenu: CustomColumnMenu,
          }}
          align="center"
          rowsPerPageOptions={[100]}
          checkboxSelection
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(row) => row.SKU}
          sx={{
            border: "none",
            boxShadow: 2,
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "black",
              color: "white",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-sortIcon": {
              color: "white",
            },
            "& .MuiDataGrid-menuIconButton": {
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
              color: "white", // Change this to your desired color for header checkbox
            },
            "& .MuiDataGrid-row .MuiCheckbox-root": {
              color: "black", // Change this to your desired color for row checkboxes
            },
            "& .MuiCircularProgress-colorPrimary": {
              color: "black !important",
            },
          }}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </Box>
  );
};

export default Home;
