import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "./auth";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const success = await login(email, password);
    if (success) {
      navigate("/");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };
  return (
    <Card
      className="center-screen custom-shadow animate fadeIn"
      sx={{ minWidth: 275, padding: 3, borderRadius: 10 }}
    >
      <CardMedia
        component="img"
        src="/images/FAIRLIAR_LOGO.png"
        alt="fair liar logo"
        sx={{ width: 140, height: 49.46, display: "unset" }}
      />
      <CardContent>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
            display: "flex",
            flexDirection: "column",
          }}
          noValidate
          autoComplete="off"
          onKeyDown={handleKeyDown}
        >
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            value={password || ""}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleLogin}
          variant="contained"
          size="large"
          sx={{
            backgroundColor: "#000000",
            ":hover": {
              bgcolor: "#000000b8",
            },
          }}
        >
          LOGIN
        </Button>
      </CardActions>
    </Card>
  );
};

export default Login;
