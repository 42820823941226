//import axios from "axios";

const login = (email, password) => {
  try {
    if (email === "fairliar" && password === "flusa123") {
      localStorage.setItem("authToken", true);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }

  //   try {
  //     const response = await axios.post('/login', { username, password });
  //     const { token } = response.data;
  //     localStorage.setItem('authToken', token);
  //     return true;
  //   } catch (error) {
  //     return false;
  //   }
};

const logout = () => {
  localStorage.removeItem("authToken");
};

const isAuthenticated = () => {
  return localStorage.getItem("authToken") !== null;
};

export { login, logout, isAuthenticated };
